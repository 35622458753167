html {
  font-size: 10px;
}

body {
  font-size: 1.4em;
}

.MuiButtonBase-root.MuiButton-root {
  text-transform: initial;
  line-height: 16px;
  min-height: 32px;
  &.MuiButton-sizeSmall {
    padding: 7px 22px;
  }
  &.MuiButton-sizeMedium {
    padding: 4px 22px;
    min-height: 42px;
  }
  &.MuiButton-sizeLarge {
    padding: 2px 22px;
    min-height: 42px;
  }
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.MuiPopover-paper a {
  color: var(--ion-text-color);
}

.MuiTableCell-root div.mod {
  opacity: 0.75;
  font-size: 0.8em;
}
