body {
  font-size: 1.5rem;
  line-height: normal;
  justify-content: flex-start;
}

.pointer {
  cursor: pointer;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.hide {
  display: none !important;
}

.quarter {
  width: 25%;
}

.third {
  width: 33%;
}

.half {
  width: 50%;
}

.inline {
  display: inline-block;
}

.bold {
  font-weight: bold;
}

.semi-bold {
  font-weight: 600;
}

.word-break {
  word-break: break-all;
}

.disabled {
  background-color: rgba(0, 0, 0, 0.1);
  pointer-events: none;
  opacity: 0.8;
}

.disabled {
  background-color: rgba(0, 0, 0, 0.1);
  pointer-events: none;
  opacity: 0.8;
}

.strikethrough {
  text-decoration: line-through;
}

.center {
  text-align: center !important;
  margin-left: auto !important;
  margin-right: auto !important;
  justify-content: center;
}
.right {
  text-align: right;
  justify-content: flex-end;
}

.flex-center,
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pull-right {
  float: right;
}

.no-space {
  padding: 0px !important;
  min-height: 0 !important;
  svg {
    margin: 0 !important;
  }
}

.pull-left {
  float: left;
}

.flattened {
  height: 0 !important;
}

a {
  text-decoration: auto;
}

img {
  max-width: initial;
}

.link {
  color: var(--ion-color-primary);
  cursor: pointer;
}
.link.secondary { color: var(--ion-color-secondary) }
.link.tertiary { color: var(--ion-color-tertiary); }
.link.success { color: var(--ion-color-success); }
.link.warning { color: var(--ion-color-warning); }
.link.danger { color: var(--ion-color-danger); }
.link.dark { color: var(--ion-color-dark); }
.link.medium { color: var(--ion-color-medium); }
.link.light { color: var(--ion-color-light); }

.round {
  border-radius: 100%;
}

.error {
  color: var(--ion-color-danger);
}

.transparent {
  color: transparent;
}

/* dialog */
ion-alert.large {
  --width: 400px;
  --max-width: 340px;
}

/* toaster */
ion-toast {
  max-width: 400px;
  margin: 0 auto;
  min-height: 64px;
  height: auto;
  position: relative;
}
ion-toast.small {
  max-width: 250px;
}
ion-toast.large {
  max-width: 600px;
}

.ion-overlay-wrapper {
  --min-width: 300px;
}

.readonly {
  pointer-events: none;
}

ion-item.readonly ion-select::part(icon) {
  display: none;
}

ion-content ion-item.item {
  ion-select,
  ion-datetime,
  ion-input {
    font-size: 1.4rem;
  }
  ion-label.hydrated {
    font-size: 1.4rem;
  }
}

ion-title {
  font-size: 18px;
  font-weight: 600;
}

textarea {
  min-height: 18px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

svg.MuiSvgIcon-root {
  font-size: 2.4rem;
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeLarge {
  font-size: 3.5rem;
}

ion-modal {
  .modal-wrapper {
    // --background: rgb(0, 0, 0);
    // --color: #333;

    ion-content,
    ion-header,
    ion-footer,
    ion-toolbar {
      --background: #fff;
      --color: #333;
    }

    .MuiFormLabel-root {
      color: #333;
    }
  }
}

ion-loading {
  .loading-wrapper {
    .loading-spinner {
      color: #428cff;
      --spinner-color: #428cff;
      font-weight: 600;
    }
  }
}

ion-alert {
  .alert-wrapper {
    --background: #fff;
    --color: #000;

    .alert-title,
    .alert-message {
      color: #000;
    }
    .alert-button {
      color: #428cff;
      font-weight: 600;
    }
  }
}

.MuiIconButton-root {
  flex: 0 0 auto;
  color: rgba(0, 0, 0, 0.54);
  padding: 12px;
  overflow: visible;
  font-size: 2.4rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
}

.mui-dialog {
  .MuiDialog-paperWidthSm {
    max-width: 95%;
  }
  .MuiDialog-paper {
    margin: 0;
  }
}

/* MATERIAL DEFAULT */
.MuiSelect-select:focus {
  background-color: initial;
}
.MuiFormLabel-root {
  font-size: inherit;
}
.MuiInputAdornment-root ion-icon {
  font-size: 2rem;
}

.site-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 8px;
}

/* MATERIAL UI INPUT COLORS */
.MuiFormLabel-root {
  color: var(--ion-text-color);
}

.MuiButtonGroup-grouped {
  min-height: 40px;
  span {
    line-height: normal;
  }
}
.MuiInputBase-input.Mui-disabled {
  background-color: rgba(var(--ion-color-light-rgb), 0.2);
}
.MuiButtonBase-root.multiline {
  line-height: 14px;
}
.Mui-error {
  line-height: 13px;
}

/* @media screen and (min-width: ${ENUMS.SPLIT_PANE_MIN_WIDTH}) {
  .can-go-back > ion-header ion-back-button {
    display: none !important;
  }
} */

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
  display: flex;
  align-items: center;
}

// Disabled input
.MuiInputBase-root.Mui-disabled {
  opacity: 0.9;
  input {
    background-color: rgba(0, 0, 0, 0.13);
  }
}

.w100 { width: 100% }
.w90 { width: 90% }
.w80 { width: 80% }
.w70 { width: 70% }
.w60 { width: 60% }
.w50 { width: 50% }
.w40 { width: 40% }
.w30 { width: 30% }
.w20 { width: 20% }
.w10 { width: 10% }
.w75 { width: 75% }
.w25 { width: 25% }
.w66 { width: 66% }
.w5 { width: 5% }
.w95 { width: 95% }

// Opacity
.o10 {
  opacity: 0.1;
}
.o20 {
  opacity: 0.2;
}
.o30 {
  opacity: 0.3;
}
.o40 {
  opacity: 0.4;
}
.o50 {
  opacity: 0.5;
}
.o60 {
  opacity: 0.6;
}
.o70 {
  opacity: 0.7;
}
.o80 {
  opacity: 0.8;
}
.o90 {
  opacity: 0.9;
}

// Dropzone
.drop-container {
  display: flex;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  background-color: #eee;
  border-color: #ccc;
  color: var(--ion-color);
  outline: none;
  transition: border 0.24s ease-in-out;
  height: 100%;

  p {
    display: flex;
    align-items: center;
    height: 100%;
    text-align: center;
  }
}

.alert-message {
  color: var(--ion-text-color);
}

.warning.outlined {
  color: var(--ion-color-warning);
  border-color: var(--ion-color-warning);
}
.success.outlined {
  color: var(--ion-color-success);
  border-color: var(--ion-color-success);
}
.danger.outlined {
  color: var(--ion-color-danger);
  border-color: var(--ion-color-danger);
}
.primary.outlined {
  color: var(--ion-color-primary);
  border-color: var(--ion-color-primary);
}
.secondary.outlined {
  color: var(--ion-color-secondary);
  border-color: var(--ion-color-secondary);
}
.tertiary.outlined {
  color: var(--ion-color-tertiary);
  border-color: var(--ion-color-tertiary);
}
.outlined.dark {
  color: var(--ion-color-dark);
  border-color: var(--ion-color-tertiary);
}
.outlined.light {
  color: var(--ion-color-light);
  border-color: var(--ion-color-light);
}
.warning.filled {
  color: var(--ion-color-warning-contrast-bw);
  background-color: var(--ion-color-warning);
}
.success.filled {
  color: var(--ion-color-success-contrast-bw);
  background-color: var(--ion-color-success);
}
.danger.filled {
  color: var(--ion-color-danger-contrast-bw);
  background-color: var(--ion-color-danger);
}
.primary.filled {
  color: var(--ion-color-primary-contrast-bw);
  background-color: var(--ion-color-primary);
}
.secondary.filled {
  color: var(--ion-color-secondary-contrast-bw);
  background-color: var(--ion-color-secondary);
}
.tertiary.filled {
  color: var(--ion-color-tertiary-contrast-bw);
  background-color: var(--ion-color-tertiary);
}
.dark.filled {
  color: var(--ion-color-dark-contrast-bw);
  background-color: var(--ion-color-dark);
}
.light.filled {
  color: var(--ion-color-light-contrast-bw);
  background-color: var(--ion-color-light);
}

.uppercase {
  text-transform: uppercase;
}
.uppercase-words {
  text-transform: capitalize;
}
.lowercase {
  text-transform: lowercase;
}

.MuiTableCell-root.action > * > button {
  padding-top: 0;
  padding-bottom: 0;
}
.MuiTableCell-root.action .MuiSvgIcon-root {
  margin: 2px 4px;
}
.MuiButton-label {
  line-height: normal;
}

.pop-container {
  button {
    justify-content: flex-start;
  }
}
.print-only {
  display: none !important;
}

.row.checks .col {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

@media print {
  body {
    font-size: 1rem;
  }
  ion-header ion-buttons,
  .filter-section {
    display: none !important;
  }
  div.virtualized-table.MuiTable-root,
  .virtualized-row {
    max-width: 1000px !important;
    min-width: 600px !important;
  }
  .MuiTableCell-root {
    padding: 2px 2px !important;
    font-size: 0.9em !important;
  }
  .MuiTableCell-root {
    color: #000 !important;
  }
  .no-print,
  .menu-side-start,
  .MuiTableCell-root.select-cell,
  .MuiTableCell-root.action,
  .MuiTableCell-root .MuiSvgIcon-root {
    display: none !important;
  }
  .print-only {
    display: inherit !important;
  }
  ion-modal.show-modal {
    background: #fff;
    opacity: 1;
    align-items: flex-start;
    --max-height: auto !important;
    --width: 100vw !important;
    position: fixed;
    display: table;
  }

  [aria-hidden='true'] {
    display: none;
  }

  body {
    overflow: auto !important;
    position: relative;
  }
  ion-content,
  .ion-page,
  ion-split-pane,
  ion-router-outlet {
    position: relative;
    contain: none !important;
    flex: none !important;
  }
  ion-content::part(scroll) {
    position: relative;
  }
  .page-break-after {
    page-break-after: always;
  }
}

/* ACCESSS */
body.access_1 {
  .access_0 {
    display: none !important;
  }
  .disable_0 {
    opacity: 0.4;
    pointer-events: none;
  }
}

body.access_2 {
  .access_1,
  .access_0 {
    display: none !important;
  }
  .disable_1,
  .disable_0 {
    opacity: 0.4;
    pointer-events: none;
  }
}

body.access_3 {
  .access_2,
  .access_1,
  .access_0 {
    display: none !important;
  }
  .disable_2,
  .disable_1,
  .disable_0 {
    opacity: 0.4;
    pointer-events: none;
  }
}

body.access_4 {
  .access_3,
  .access_2,
  .access_1,
  .access_0 {
    display: none !important;
  }
  .disable_3,
  .disable_2,
  .disable_1,
  .disable_0 {
    opacity: 0.4;
    pointer-events: none;
  }
}

.draggable-row {
  left: 0px !important;
}

/* XSMALL INPUTS */
.xsmall.MuiFormControl-root,
.xsmall.MuiInputBase-root {
  .MuiInputLabel-animated:not(.Mui-focused) {
    transform: translate(14px, 6px) scale(1);
  }
  input {
    padding: 5.5px 14px;
    font-size: 1.4rem;
  }
  .MuiSelect-select {
    padding: 5.5px 14px;
    font-size: 1.4rem;
  }
}
.xsmall.MuiAutocomplete-root.MuiAutocomplete-fullWidth {
  > .MuiFormControl-root {
    input {
      padding: 5.5px 14px !important;
      font-size: 1.4rem;
    }
  }
}
.MuiTextField-root.currency {
  input {
    padding-left: 0;
  }
}
